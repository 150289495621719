import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Club from "../../components/club"
import Map from "../../components/map"

import "../../styles/boathouse.scss"

function Boathouse(props) {
  const { boathouse } = props.data

  const image = getImage(boathouse.primaryPhoto);
  let landscapeClass = 'boathouse';
  if (boathouse.primaryPhoto.file.details.image.height < boathouse.primaryPhoto.file.details.image.width) {
      landscapeClass = 'boathouse land';
  }
  const rowingClubs = boathouse.rowingClubs || [];

  const clubsHeading = (clubs) => {
      if (clubs.length > 0) {
          return <h3>Clubs</h3>
      }
      return false
  };

  const boathousesGeoJSON = {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "properties": {"title": boathouse.name},
      "geometry": {
        "type": "Point",
        "coordinates": [ boathouse.location.lon, boathouse.location.lat ]
      }
    }]
  };

  return (
  <Layout>
    <title>{ boathouse.name } - Cambridge Boathouses</title>
    <main className={landscapeClass}>
        <GatsbyImage image={image} alt={boathouse.name} placeholder="blurred" layout="full-width" />
        <div className="text">
            <h1>{ boathouse.name }</h1>
            { clubsHeading(rowingClubs) }
            { rowingClubs.map((club) => {
            return (
                <Club club={club} key={club.id} />
            )
            })}
            <Map geoJSON={ boathousesGeoJSON } />
            <Link to="/">Back to home</Link>
        </div>
    </main>
  </Layout>
  )
}

export default Boathouse

export const query = graphql`
  query($slug: String!) {
    boathouse: contentfulBoathouse(slug: { eq: $slug }) {
        id
        name
        slug
        location {
          lat
          lon
        }
        primaryPhoto {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          gatsbyImageData(width: 1200, placeholder: BLURRED)
        }
        rowingClubs {
          name
          boatCode
          id
        }
    }
  }
`