import React, { useRef, useEffect, useState } from "react"
import mapboxgl from '!mapbox-gl';

import "mapbox-gl/dist/mapbox-gl.css"

mapboxgl.accessToken = 'pk.eyJ1IjoiY2JnYm9hdGhvdXNlcyIsImEiOiJja3M0ZnU2MXIwazIwMnBsbG5zdmtra3YwIn0.s_wJZvOvvjXOkNmZr5ikNA';

export default function Map({ geoJSON }) {
    const mapContainer = useRef(null);
    const map = useRef(null);

    const center = {
        lng: 0.1335,
        lat: 52.2137
    };
    if (geoJSON.features.length === 1) {
        center.lng = geoJSON.features[0].geometry.coordinates[0];
        center.lat = geoJSON.features[0].geometry.coordinates[1];
    };

    const [lng] = useState(center.lng);
    const [lat] = useState(center.lat);
    const [zoom] = useState(17);
    
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/cbgboathouses/cks6gockl9kq317o52danzwxw',
            center: [lng, lat],
            zoom: zoom,
            pitch: 60
        })
        map.current.on('load', () => {
            map.current.addSource('boathouses', {
                type: "geojson",
                data: geoJSON
            });
            map.current.addLayer({
                "id": "boathouses",
                "type": "symbol",
                "source": "boathouses",
                "layout": {
                    "icon-image": "embassy-11",
                    "icon-allow-overlap": true,
                    'text-field': ['get', 'title'],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-offset': [0, 1.25],
                    'text-anchor': 'top'
                }
            });
        });
    });
    
    return (
        <div ref={mapContainer} className="map-container" />
    )
}